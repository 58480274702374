import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Menu, Transition } from "@headlessui/react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons"

const array = [
  {
    index: 0,
    title: "adobe",
    link: "/products/applications/#adobe",
  },
  {
    index: 1,
    title: "corel draw",
    link: "/products/applications/#corelDraw",
  },
  {
    index: 2,
    title: "team viewer",
    link: "/products/applications/#teamViewer",
  },
  {
    index: 3,
    title: "arcserve",
    link: "/products/applications/#arcserve",
  },
  {
    index: 4,
    title: "Diecentral",
    link: "/products/applications/#Diecentral",
  },
  {
    index: 5,
    title: "Freshworks",
    link: "/products/applications/#Freshworks",
  },
  {
    index: 6,
    title: "HCL",
    link: "/products/applications/#HCL",
  },
  {
    index: 7,
    title: "Kapture",
    link: "/products/applications/#Kapture",
  },
  {
    index: 8,
    title: "Proaxia",
    link: "/products/applications/#Proaxia",
  },
  {
    index: 9,
    title: "Siemens",
    link: "/products/applications/#Siemens",
  },
  {
    index: 10,
    title: "Tristha",
    link: "/products/applications/#Tristha",
  },
  {
    index: 11,
    title: "SAP S/4HANA",
    link: "/sap/#Sap4hana",
  },
  {
    index: 12,
    title: "Rise with SAP S/4HANA",
    link: "/sap/#riseWithSap",
  },
  {
    index: 13,
    title: "SAP Business ByDesign (ByD)",
    link: "/sap/#sapBusinessByDesign",
  },
  {
    index: 14,
    title: "SAP Business One (B1)",
    link: "/sap/#sapBusinessOne",
  },
  {
    index: 15,
    title: "SAP SuccessFactors (HCM)",
    link: "/sap/#sapSuccessFactors",
  },
  {
    index: 16,
    title: "SAP Customer Experience Management Solution",
    link: "/sap/#sapCustomerExperienceManagementSolution",
  },
  {
    index: 17,
    title: "SAP Analytics Cloud",
    link: "/sap/#sapAnalyticsCloud",
  },
  {
    index: 18,
    title: "SAP Intelligent Spend Management",
    link: "/sap/#sapIntelligentSpendManagement",
  },
]
export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      search: false,
      explore: true,
      query: "",
      items: array,
      filteredItems: [],
      searchDropdown: false,
    }

    this.searchClick = this.searchClick.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.filterItems = this.filterItems.bind(this)
    this.focusOut = this.focusOut.bind(this)
  }

  focusOut() {
    setTimeout(() => {
      this.setState({
        search: false,
        explore: true,
        searchDropdown: false,
        query: "",
        value: null,
      })
    }, 150)

    console.log("focus out")
  }

  searchClick() {
    this.setState({
      search: true,
      explore: false,
    })
  }

  onSearch(event) {
    const query = event.target.value.toLowerCase()
    this.setState({ query: query }, () => this.filterItems())
    if (query) {
      this.setState({
        searchDropdown: true,
      })
    } else {
      this.setState({
        searchDropdown: false,
      })
    }
  }

  filterItems() {
    let items = this.state.items
    let query = this.state.query

    items = items.filter(item => {
      return item.title.toLowerCase().indexOf(query) != -1
    })

    this.setState({ filteredItems: items })
  }

  render() {
    return (
      <div>
        <div className="main-header">
          <div className="container">
            <div className="sections d-flex justify-content-between align-items-center">
              <div className="menu d-flex align-items-center">
                {/* <div className="image-logo">
                  <Link to="/">
                    <StaticImage
                      src="../images/logo/logo-dark.png"
                      width={140}
                      alt="techpac"
                    />
                  </Link>
                </div> */}
                <div className="menu-items d-flex">
                  <span>
                    <Link to="/sap">SAP</Link>
                  </span>
                  <div className="dropdown d-flex flex-column">
                    <div className="menu-dropdown">
                      <Menu>
                        <Menu.Button>Products</Menu.Button>
                        <Transition
                          className="menu-dropdown-wrapper"
                          enter="transition duration-100 ease-out"
                          enterFrom="transform  opacity-0 translate-y-1"
                          enterTo="transform  opacity-100 translate-y-0"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform  opacity-100 translate-y-0"
                          leaveTo="transform  opacity-0 translate-y-1"
                        >
                          <Menu.Items className="menu-dropdown-items">
                            <Menu.Item>
                              <Link to="/products/applications">
                                <div className="dropdown-item ">
                                  Applications
                                </div>
                              </Link>
                            </Menu.Item>
                            {/* <hr /> */}
                            <Menu.Item>
                              <Link to="/products/digital-infrastructure">
                                <div className="dropdown-item">
                                  Digital Infrastructure
                                </div>
                              </Link>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <span>
                    <Link to="/about">About</Link>
                  </span>
                  <span>
                    <Link to="/success-stories">Success Stories</Link>
                  </span>
                  <span>
                    <Link to="/contact">Contact Us</Link>
                  </span>
                  <span>
                    <Link to="/blog">Blog</Link>
                  </span>
                  <span>
                    <Link to="/careers">Careers</Link>
                  </span>
                </div>
              </div>
              <div className="search" onClick={this.searchClick}>
                {this.state.search && (
                  <>
                    <input
                      autoFocus
                      type="text"
                      className="input-search-header"
                      placeholder="Search"
                      onChange={this.onSearch}
                      value={this.state.value}
                      name="searchItem"
                      autocomplete="off"
                      onBlur={this.focusOut}
                    />
                    {this.state.searchDropdown && (
                      <div className="search-dropdown">
                        <div className="dropdown-items">
                          {this.state.filteredItems.map(item => {
                            return (
                              <Link to={item.link} key={item.index}>
                                <div className="item">
                                  <span>{item.title}</span>
                                </div>
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.explore && (
                  <>
                    <div className="explore-icon">
                      <span>Explore</span>
                      <FontAwesomeIcon size="lg" icon={faSearch} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-main-header">
          <div className="container">
            <div className="mobile-menu-section d-flex justify-content-between align-items-center">
              <div className="menu-dropdown">
                <Menu>
                  <Menu.Button>
                    <div className="mobile-menu-icon">
                      <FontAwesomeIcon
                        icon={faBars}
                        className="mobile-menu-menu-icon"
                      />
                    </div>
                  </Menu.Button>
                  <Transition
                    className="menu-dropdown-wrapper"
                    enter="transition duration-100 ease-out"
                    enterFrom="transform  opacity-0 translate-y-1"
                    enterTo="transform  opacity-100 translate-y-0"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform  opacity-100 translate-y-0"
                    leaveTo="transform  opacity-0 translate-y-1"
                  >
                    <Menu.Items className="menu-dropdown-items">
                      <div className="dropdown-item">
                        <div className="search" onClick={this.searchClick}>
                          {this.state.search && (
                            <>
                              <input
                                autoFocus
                                type="text"
                                className="input-search-header"
                                placeholder="Search"
                                onChange={this.onSearch}
                                value={this.state.value}
                                name="searchItem"
                                autocomplete="off"
                                onBlur={this.focusOut}
                              />
                              {this.state.searchDropdown && (
                                <div className="search-dropdown">
                                  <div className="dropdown-items">
                                    {this.state.filteredItems.map(item => {
                                      return (
                                        <Link to={item.link} key={item.index}>
                                          <div className="item">
                                            <span>{item.title}</span>
                                          </div>
                                        </Link>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {this.state.explore && (
                            <>
                              <div className="explore-icon">
                                <span>Explore</span>
                                <FontAwesomeIcon size="lg" icon={faSearch} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <Menu.Item>
                        <Link to="/sap">
                          <div className="dropdown-item ">SAP</div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/products/applications">
                          <div className="dropdown-item ">Applications</div>
                        </Link>
                      </Menu.Item>
                      {/* <hr /> */}
                      <Menu.Item>
                        <Link to="/products/digital-infrastructure">
                          <div className="dropdown-item">
                            Digital Infrastructure
                          </div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/about">
                          <div className="dropdown-item">About</div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/success-stories">
                          <div className="dropdown-item">Success Stories</div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/contact">
                          <div className="dropdown-item">Contact Us</div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/blog">
                          <div className="dropdown-item">Blog</div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/careers">
                          <div className="dropdown-item">Careers</div>
                        </Link>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {/* <div className="mobile-menu-logo">
                <Link to="/">
                  <StaticImage
                    src="../images/logo/logo-dark.png"
                    width={100}
                    alt="techpac"
                  />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
